import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./samples.scss"

// import Sample1 from "../images/sample_1.inline.svg";
// import Sample2 from "../images/sample_2.inline.svg";
// import Sample3 from "../images/sample_3.inline.svg";

const Samples = () => {
  return (
    <>
      <div className="samples d-flex flex-column align-items-center">
        <h2>View a <br/>sample report</h2>
        <div className="samples-downloads">
          <a className="sample" href="https://poached.xyz/results/?handle=@packym" target="_blank" rel="noreferrer">
            <StaticImage src="../images/sample1.png" alt="Sample Poached Report for Packy McCormick @packym on twitter" placeholder="none" width={467}/>
          </a>
          <a className="sample" href="https://poached.xyz/results/?handle=@gaby_goldberg" target="_blank" rel="noreferrer">
            <StaticImage src="../images/sample2.png" alt="Sample Poached Report for Gaby Goldberg @gaby_goldberg on twitter" placeholder="none" width={467}/>
          </a>
          <a className="sample" href="https://poached.xyz/results/?handle=@chrislyons" target="_blank" rel="noreferrer">
            <StaticImage src="../images/sample3.png" alt="Sample Poached Report for Chris Lyons @chrislyons on twitter" placeholder="none" width={467}/>
          </a>
        </div>
      </div>
     </>
  )
}

export default Samples