import * as React from "react"
import * as queryString from "query-string";

import Seo from "../components/seo"

import "./index.scss"

import HeroFry from "../components/herofry";
import Testimonials from "../components/testimonials"
import Samples from "../components/samples";
import Poaching from "../components/poaching";
import EggCursor from "../components/eggcursor";

const IndexPage = ({location}) => {
  const { ec } = queryString.parse(location.search);
  return (
    <>
      <Seo/>
      <main>
        <EggCursor />
        <HeroFry ec={ec} />
        <Testimonials />
        <Samples />
        <Poaching />
        <footer className="container">
          <a href="https://partyround.com" target="_blank" rel="noreferrer">
            <p>A DROP BY PARTY ROUND</p>
          </a>
        </footer>
      </main>
    </>
  );
}

export default IndexPage