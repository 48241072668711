import React, { useState } from 'react'; //we already import useState and useEffect that we will use later
import "./herofry.scss"
import { StaticImage } from "gatsby-plugin-image"


import OnlyPan from "../images/onlypan.inline.svg";
import OnlyPanMobile from "../images/onlypan-mobile.inline.svg";
//import TweetBtn from "../images/hero_tweet.inline.svg";

const HeroFry = (props) => {
  const ec = props.ec;

  const [twitterHandle, setTwitterHandle] = useState('');
  const [email, setEmail] = useState('');

  const poachTwitter = () => {
    if (twitterHandle && email) {
      window.location.href = `https://api.poached.xyz/download?handle=${twitterHandle}&email=${email}`;
    } else {
      //alert('No Value Entered!');
      window.location.href = `https://poached.xyz/?ec=3`
    }
  };

  const handleChange = (event) => {
    if (event.target.getAttribute('name') === 'fhandle') setTwitterHandle(event.target.value);
    if (event.target.getAttribute('name') === 'femail') setEmail(event.target.value);
  }

  return (
    <>
      <a 
        className="position-absolute btn-tweet"
        href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fpoached.xyz&text=Check%20out%20Poached%20Eggs%2C%20a%20Twitter%20hiring%20tool%20by%20%40PartyRound"
        target="_blank" rel="noreferrer"
      >
        <StaticImage src="../images/results_btn-share.png" alt="Home" placeholder="none" width={100}/>
      </a>
      
      <div className="hero d-flex flex-column align-items-center">
          <div className="hero-brand text-center">
            <h6>POACHED EGGS</h6>
            <a href="https://partyround.com" target="_blank" rel="noreferrer">
              <p>A DROP BY PARTY ROUND</p>
            </a>
          </div>
          <div className="hero-cta d-flex flex-column align-items-center">
            <h1 className="text-center">Enter a handle. <br/> Hire their followers.</h1>
            <h5 className="text-center">Pick anyone’s twitter and we’ll email you candidate profiles of their followers.</h5>
          </div>
          <div className="hero-form">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <input name="fhandle" onChange={handleChange} className="col form-control form-control-lg" type="text" placeholder="Enter any twitter handle" aria-label="enter twitter handle"/>
              <input name="femail" onChange={handleChange} className="col form-control form-control-lg" type="text" placeholder="Enter your email" aria-label="enter email"/>
            </div>
            <div className="d-flex">
              <a 
                className="btn-submit" href="/#"
                role="button" tabIndex={0} onClick={poachTwitter} onKeyDown={poachTwitter}
              >
                  START POACHING
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <p className='text-center text-light '>
                {
                  (parseInt(ec) === 1 ? 'The handle you entered doesn\'t have any poachable followers!' :
                  (parseInt(ec) === 2 ? 'The handle you entered has too many followers!' : 
                  (parseInt(ec) === 3 ? 'Please Entere a Twitter Handle' : '')))
                }
              </p>
            </div>
          </div>
      </div>
      <div>
        <div className="mobile-pan position-absolute top-0 start-50 translate-middle-x">
          <OnlyPan 
            alt="The Only Pan on the Page"
            className="onlypan"
          />
          <OnlyPanMobile
            alt="The Only Pan on the Page"
            className="img-fluid onlypan-mobile"
          />       
        </div>
      </div>
    </>
  )
}

export default HeroFry
