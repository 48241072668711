import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./poaching.scss"
import PoachedEgg from "../images/poaching_egg.inline.svg";
import StartBtn from "../images/poaching_btnstart.inline.svg";


const Poaching = () => {
  const returnToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <>
      <div className="poaching-profiles position-relative mx-auto  d-none d-lg-block">
        <StaticImage width={426} className="profile1 position-absolute" src="../images/profile1.png" alt="Profile1" placeholder="none" />
        <StaticImage width={430} className="profile2 position-absolute" src="../images/profile2.png" alt="Profile2" placeholder="none" />
        <StaticImage width={443} className="profile3 position-absolute" src="../images/profile3.png" alt="Profile3" placeholder="none" />
        <StaticImage width={432} className="profile4 position-absolute" src="../images/profile4.png" alt="Profile4" placeholder="none" />
        <StaticImage width={439} className="profile5 position-absolute" src="../images/profile5.png" alt="Profile5" placeholder="none" />
        <StaticImage width={453} className="profile6 position-absolute" src="../images/profile6.png" alt="Profile6" placeholder="none" />
      </div>
      <div className="poaching d-flex justify-content-center align-items-center">
        <div 
          className="poaching-cta"
          // data-sal="slide-up"
          // data-sal-duration="500"
          // data-sal-delay="10"
          // data-sal-easing="ease"
        >
          <div className="profileTop d-inline-block d-md-none">
            <StaticImage src="../images/profile_top.png" alt="3 Tweets" placeholder="none"/>
          </div>
          <h2>Start poaching your competitors' employees today.</h2>
          <div className="profileTop d-inline-block d-md-none">
            <StaticImage src="../images/profile_bottom.png" alt="3 Tweets" placeholder="none"/>
          </div>
        
          <div className="btn-start d-none d-lg-block" role="button" tabIndex={0} onClick={returnToTop} onKeyDown={returnToTop}>
            <StartBtn alt="Pixalated Get Started button" />
          </div>
        </div>

        <PoachedEgg
            alt="Image of a Poached Egg" 
            className="d-none d-lg-block"
            // data-sal="slide-up"
            // data-sal-duration="1000"
            // data-sal-delay="100"
            // data-sal-easing="ease"
        />
      </div>
     </>
  )
}

export default Poaching