import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./testimonials.scss"

//import Tweet1 from "../images/tweet_1.inline.svg";
//import Tweet2 from "../images/tweet_2.inline.svg";
//import Tweet3 from "../images/tweet_3.inline.svg";
// import Tweet4 from "../images/tweet_4.inline.svg";
//import Tweet5 from "../images/tweet_5.inline.svg";


const Testimonials = () => {
  return (
    <>
      <div className="wave-border"></div>
      <div className="testimonials d-block d-md-flex flex-column align-items-center ">
        <div className="position-relative d-none d-md-block">
          <a className="tweet1 position-absolute" href="https://twitter.com/ybelyayeva/status/1317143630658834433" target="_blank" rel="noreferrer">
            <StaticImage src="../images/tweet1.png" alt="A tweet by @ybelyayeva" placeholder="none" width={325}/>
          </a>
          <a className="tweet2 position-absolute" href="https://twitter.com/hotgirlintech/status/1376324477311610880" target="_blank" rel="noreferrer">
            <StaticImage src="../images/tweet2.png" alt="A tweet by @hotgirlintech" placeholder="none" width={325}/>
          </a>
          <a className="tweet3 position-absolute" href="https://twitter.com/gregisenberg/status/1378396001988411400" target="_blank" rel="noreferrer">
            <StaticImage src="../images/tweet3.png" alt="A tweet by @greisenberg" placeholder="none" width={462}/>
          </a>
          <a className="tweet5 position-absolute" href="https://twitter.com/ParikPatelCFA/status/1387149670036557828" target="_blank" rel="noreferrer">
            <StaticImage src="../images/tweet5.png" alt="A tweet by @parikpatelcfa" placeholder="none" width={365}/>
          </a>
          <a className="tweet4 position-absolute" href="https://twitter.com/martyrdison/status/1400323055876853765" target="_blank" rel="noreferrer">
            <StaticImage src="../images/tweet4.png" alt="A tweet by @martydison" placeholder="none" width={313}/>
          </a>
        </div>
        <div className="tweetTop d-inline-block d-md-none">
          <StaticImage src="../images/tweet_top.png" alt="3 Tweets" />
        </div>
        <h2>The best hires come from Twitter, so we productized it.</h2>
        <StaticImage src="../images/tweet_bottom.png" alt="3 Tweets" className="tweetBottom d-block d-md-none"/>
      </div>
    </>
  )
}

export default Testimonials
